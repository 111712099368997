import { nFormatter } from '@/public/js/util';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = {
  audienceLocations: [],
  defaultLocationFilter: [],
  audienceAge: { value: null, minPercent: null, primaryLabel: null },
  audienceGender: { gender: null, minPercent: null },
  influencerSize: {
    label: null,
    minPercent: null,
    maxPercent: null,
    primaryLabel: null,
  },
  languageList: [
    { value: 'hi', title: 'Hindi' },
    { value: 'en', title: 'English' },
    { value: 'ta', title: 'Tamil' },
    { value: 'bn', title: 'Bengali' },
    { value: 'te', title: 'Telugu' },
    { value: 'ml', title: 'Malyalam' },
    { value: 'mr', title: 'Marathi' },
    { value: 'gu', title: 'Gujarati' },
    { value: 'pa', title: 'Punjabi' },
    { value: 'or', title: 'Odia' },
    { value: 'ka', title: 'Kannada' },
  ],
  categories: [],
  keywords: [],
  searchPhrase: null,
  influencerGender: null,
  influencerLocation: [],
  influencerLanguage: [],
  influencerContactAvailable: null,
  averageLikes: { min: null, max: null },
  estimatedReach: {
    image_posts: { min: null, max: null, checked: false },
    reel_posts: { min: null, max: null, checked: false },
    story_posts: { min: null, max: null, checked: false },
    video_posts: { min: null, max: null, checked: false },
    short_posts: { min: null, max: null, checked: false },
  },
  estimatedImpressions: {
    image_posts: { min: null, max: null, checked: false },
    reel_posts: { min: null, max: null, checked: false },
    story_posts: { min: null, max: null, checked: false },
    video_posts: { min: null, max: null, checked: false },
    short_posts: { min: null, max: null, checked: false },
  },
  estimatedPrice: {
    image_posts: { min: null, max: null, checked: false },
    reel_posts: { min: null, max: null, checked: false },
    story_posts: { min: null, max: null, checked: false },
    video_posts: { min: null, max: null, checked: false },
    short_posts: { min: null, max: null, checked: false },
  },
  filterChicklets: [],
};

export const flatFiltersSlice = createSlice({
  name: 'flatFilters',
  initialState,
  reducers: {
    // Action to add
    setAudienceLocation: (state, action) => {
      state.audienceLocations = action.payload;
    },
    setAudienceLocationPercent: (state, action) => {
      state.audienceLocations = state.audienceLocations.map((loc) => {
        if (loc.name == action.payload.value) {
          loc.GTE = action.payload.GTE;
          loc.secondaryLabel = action.payload.secondaryLabel;
        }
        return loc;
      });
    },
    setAudienceAge: (state, action) => {
      state.audienceAge = action.payload;
    },
    setAudienceGender: (state, action) => {
      state.audienceGender = action.payload;
    },
    setInfluencerSize: (state, action) => {
      state.influencerSize = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload.categories;
    },
    setKeywords: (state, action) => {
      state.keywords = action.payload.keywords;
    },
    setInfluencerGender: (state, action) => {
      state.influencerGender = action.payload;
    },
    setInfluencerLanguage: (state, action) => {
      state.influencerLanguage = action.payload;
    },
    setInfluencerContactAvailable: (state, action) => {
      state.influencerContactAvailable = action.payload;
    },
    setPerformanceFilters: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    setInfluencerLocation: (state, action) => {
      state.influencerLocation = action.payload;
    },
    setEstimatedFilters: (state, action) => {
      state[action.payload.filter][action.payload.key] = action.payload.value;
    },
    setSearchPhrase: (state, action) => {
      state.searchPhrase = action.payload;
    },
    resetPlatformFlatFilters: (state) => {
      state.estimatedReach = {
        image_posts: { min: null, max: null, checked: false },
        reel_posts: { min: null, max: null, checked: false },
        story_posts: { min: null, max: null, checked: false },
        video_posts: { min: null, max: null, checked: false },
        short_posts: { min: null, max: null, checked: false },
      };
      state.estimatedImpressions = {
        image_posts: { min: null, max: null, checked: false },
        reel_posts: { min: null, max: null, checked: false },
        story_posts: { min: null, max: null, checked: false },
        video_posts: { min: null, max: null, checked: false },
        short_posts: { min: null, max: null, checked: false },
      };
      state.estimatedPrice = {
        image_posts: { min: null, max: null, checked: false },
        reel_posts: { min: null, max: null, checked: false },
        story_posts: { min: null, max: null, checked: false },
        video_posts: { min: null, max: null, checked: false },
        short_posts: { min: null, max: null, checked: false },
      };
    },
    resetFlatFilters: (state) => {
      state.filterChicklets = [];
      state.audienceLocations = [];
      state.audienceAge = { value: null, minPercent: null, primaryLabel: null };
      state.audienceGender = { gender: null, minPercent: null };
      state.influencerSize = {
        label: null,
        minPercent: null,
        maxPercent: null,
        primaryLabel: null,
      };
      state.categories = [];
      state.keywords = [];
      state.searchPhrase = null;
      state.influencerGender = null;
      state.influencerLocation = [];
      state.influencerLanguage = [];
      state.influencerContactAvailable = null;
      state.averageLikes = { min: null, max: null };
      state.estimatedReach = {
        image_posts: { min: null, max: null, checked: false },
        reel_posts: { min: null, max: null, checked: false },
        story_posts: { min: null, max: null, checked: false },
        video_posts: { min: null, max: null, checked: false },
        short_posts: { min: null, max: null, checked: false },
      };
      state.estimatedImpressions = {
        image_posts: { min: null, max: null, checked: false },
        reel_posts: { min: null, max: null, checked: false },
        story_posts: { min: null, max: null, checked: false },
        video_posts: { min: null, max: null, checked: false },
        short_posts: { min: null, max: null, checked: false },
      };
      state.estimatedPrice = {
        image_posts: { min: null, max: null, checked: false },
        reel_posts: { min: null, max: null, checked: false },
        story_posts: { min: null, max: null, checked: false },
        video_posts: { min: null, max: null, checked: false },
        short_posts: { min: null, max: null, checked: false },
      };
    },
    setFlatFiltersFromResponse: (state, action) => {
      try {
        let followersGTEvalue = null;
        let followersLTEvalue = null;
        let followersPredefinedCategory = false;
        action.payload.forEach((x) => {
          if (x.field.includes('audience_location') && x.filterType == 'GTE') {
            let locArr = x.field.split('.');
            let tempLoc = locArr[locArr.length - 1];
            let locObj =
              x.field === 'audience_location.country.IN'
                ? {
                    name: 'IN',
                    fullName: 'India',
                    type: 'country',
                    primaryLabel: 'India',
                    GTE: x.value || '0',
                    secondaryLabel: x.value ? ` >${x.value}` : '',
                    filterField: 'country.IN',
                  }
                : {
                    name: tempLoc,
                    fullName: tempLoc + ', India',
                    type: locArr[locArr.length - 2],
                    primaryLabel: tempLoc + ', India',
                    GTE: x.value || '0',
                    secondaryLabel: x.value ? ` >${x.value}` : '',
                    filterField: `${locArr[locArr.length - 2]}.${tempLoc}`,
                  };
            state.audienceLocations.push(locObj);
          } else if (x.field == 'followers') {
            const filterType = x.filterType;
            if (filterType === 'GTE') followersGTEvalue = x.value;
            else if (filterType === 'LTE') followersLTEvalue = x.value;
            if (followersGTEvalue && followersLTEvalue) {
              if (followersGTEvalue === '1' && followersLTEvalue === '99999') {
                state.influencerSize.label = 'Nano';
                state.influencerSize.primaryLabel = '1k-99k';
                followersPredefinedCategory = true;
              } else if (
                followersGTEvalue === '100000' &&
                followersLTEvalue === '999999'
              ) {
                state.influencerSize.label = 'Micro';
                state.influencerSize.primaryLabel = '100k-999k';
                followersPredefinedCategory = true;
              } else if (
                followersGTEvalue === '1000000' &&
                followersLTEvalue === '10000000'
              ) {
                state.influencerSize.label = 'Macro';
                state.influencerSize.primaryLabel = '1M-10M';
                followersPredefinedCategory = true;
              }
            }
          } else if (x.field.includes('audience_age')) {
            let ageRange = x.field.split('.')[1];
            state.audienceAge.value = ageRange;
            state.audienceAge.minPercent = x.value;
            ageRange = ageRange.split('-');
            state.audienceAge.primaryLabel = ageRange[0] + 'yrs-';
            if (ageRange.length > 1) {
              state.audienceAge.primaryLabel += ageRange[1] + 'yrs';
            }
          } else if (x.field.includes('audience_gender')) {
            let tempGender = x.field.split('.')[1];
            tempGender = tempGender.split('_')[0];
            tempGender = tempGender[0].toUpperCase() + tempGender.slice(1);
            state.audienceGender.minPercent = x.value;
            state.audienceGender.gender = tempGender;
          } else if (x.field == 'categories') {
            state.categories = x.value.split(',');
          } else if (x.field == 'search_phrase') {
            state.searchPhrase = x.value;
          } else if (x.field == 'keywords') {
            state.keywords = x.value.split(',');
          } else if (x.field == 'location') {
            let tempLoc = x.value.split(',');
            tempLoc.forEach((y) => {
              let yDetails = y.split('_');
              state.influencerLocation.push(
                y === 'country_IN'
                  ? {
                      name: 'IN',
                      fullName: 'India',
                      type: 'country',
                    }
                  : {
                      name: yDetails[1],
                      fullName: yDetails[1] + ', India',
                      type: yDetails[0],
                    }
              );
            });
          } else if (x.field == 'gender') {
            state.influencerGender = x.value;
          } else if (x.field == 'languages') {
            let tempL = x.value.split(',');
            state.languageList.forEach((y) => {
              if (tempL.includes(y.value)) {
                state.influencerLanguage.push(y);
              }
            });
          } else if (x.field == 'flag_contact_info_available') {
            state.influencerContactAvailable = x.value == 'true';
          } else if (x.field == 'avg_likes') {
            if (x.filterType == 'GTE') {
              state.averageLikes.min = x.value;
            } else {
              state.averageLikes.max = x.value;
            }
          } else if (x.field.includes('est_reach.')) {
            let tempKey = x.field.split('.')[1];
            state.estimatedReach[tempKey].checked = true;
            if (x.filterType == 'GTE')
              state.estimatedReach[tempKey].min = x.value;
            else state.estimatedReach[tempKey].max = x.value;
          } else if (x.field.includes('est_post_price.')) {
            let tempKey = x.field.split('.')[1];
            state.estimatedPrice[tempKey].checked = true;
            if (x.filterType == 'GTE')
              state.estimatedPrice[tempKey].min = x.value;
            else state.estimatedPrice[tempKey].max = x.value;
          } else if (x.field.includes('est_impressions.')) {
            let tempKey = x.field.split('.')[1];
            state.estimatedImpressions[tempKey].checked = true;
            if (x.filterType == 'GTE')
              state.estimatedImpressions[tempKey].min = x.value;
            else state.estimatedImpressions[tempKey].max = x.value;
          }
        });
        if (
          !followersPredefinedCategory &&
          (followersGTEvalue || followersLTEvalue)
        ) {
          state.influencerSize.label = 'Custom';
          state.influencerSize.min = followersGTEvalue;
          state.influencerSize.max = followersLTEvalue;
          state.influencerSize.primaryLabel = `Custom`;
        }
      } catch (error) {
        console.error(error);
        return state;
      }
    },
    updateDefaultLocationFilter: (state, action) => {
      state.defaultLocationFilter = action.payload;
    },
    setFilterChicklets: (state) => {
      const keyValuePostTypes = {
        image_posts: 'Image',
        reel_posts: 'Reel',
        story_posts: 'Story',
        video_posts: 'Video',
        short_posts: 'Short',
      };
      const tempFilterChicklets = [];
      if (state.searchPhrase) {
        tempFilterChicklets.push({
          key: 'search_phrase',
          title: 'Creator name/handle',
          value: state.searchPhrase,
        });
      }
      if (state.influencerSize.primaryLabel) {
        tempFilterChicklets.push({
          key: 'followers',
          title: 'Influencer Size',
          value: state.influencerSize.primaryLabel,
        });
      }
      if (state.audienceLocations.length) {
        tempFilterChicklets.push({
          key: 'audience_location',
          title: 'Audience Location',
          value: state.audienceLocations
            .map(
              (l) =>
                ' ' + l.primaryLabel + ',' + (l.secondaryLabel || ' >0%') + ' '
            )
            .join('|'),
        });
      }
      if (state.audienceAge.value) {
        tempFilterChicklets.push({
          key: 'audience_age',
          title: 'Audience Age',
          value:
            state.audienceAge.primaryLabel +
            `, >${state.audienceAge.minPercent || 0}%`,
        });
      }
      if (state.audienceGender.gender) {
        tempFilterChicklets.push({
          key: 'audience_gender',
          title: 'Audience Gender',
          value:
            state.audienceGender.gender +
            `, >${state.audienceGender.minPercent || 0}%`,
        });
      }
      if (state.categories.length) {
        tempFilterChicklets.push({
          key: 'categories',
          title: 'Categories',
          value: state.categories.join(', '),
        });
      }
      if (state.keywords.length) {
        tempFilterChicklets.push({
          key: 'keywords',
          title: 'Keywords',
          value: state.keywords.join(', '),
        });
      }
      if (state.influencerLocation.length) {
        tempFilterChicklets.push({
          key: 'location',
          title: 'Influencer Location',
          value: state.influencerLocation.map((l) => l.fullName).join(' | '),
        });
      }
      if (state.influencerGender) {
        tempFilterChicklets.push({
          key: 'gender',
          title: 'Influencer Gender',
          value: state.influencerGender,
        });
      }
      if (state.influencerLanguage.length) {
        tempFilterChicklets.push({
          key: 'languages',
          title: 'Influencer Language',
          value: state.influencerLanguage.map((l) => l.title).join(', '),
        });
      }
      if (state.influencerContactAvailable != null) {
        tempFilterChicklets.push({
          key: 'flag_contact_info_available',
          title: 'Contact available',
          value: state.influencerContactAvailable ? 'Yes' : 'No',
        });
      }

      if (state.averageLikes.min || state.averageLikes.max) {
        tempFilterChicklets.push({
          key: 'avg_likes',
          title: 'Average Likes',
          value:
            state.averageLikes.min && state.averageLikes.max
              ? `${state.averageLikes.min}-${state.averageLikes.max}`
              : state.averageLikes.min
              ? `>${state.averageLikes.min}`
              : `<${state.averageLikes.max}`,
        });
      }
      let tempValues = [];
      Object.entries(state.estimatedPrice).forEach(([k, v]) => {
        if (v.min || v.max) {
          let tempValue =
            v.min && v.max
              ? `${v.min}-${v.max}`
              : v.min
              ? `>${v.min}`
              : `<${v.max}`;

          tempValues.push(keyValuePostTypes[k] + ', ' + tempValue);
        }
      });
      if (tempValues.length) {
        tempFilterChicklets.push({
          key: 'est_post_price',
          title: 'Estimated post price',
          value: tempValues.join(' | '),
        });
      }

      tempValues = [];
      Object.entries(state.estimatedReach).forEach(([k, v]) => {
        if (v.min || v.max) {
          let tempValue =
            v.min && v.max
              ? `${v.min}-${v.max}`
              : v.min
              ? `>${v.min}`
              : `<${v.max}`;

          tempValues.push(keyValuePostTypes[k] + ', ' + tempValue);
        }
      });
      if (tempValues.length) {
        tempFilterChicklets.push({
          key: 'est_reach',
          title: 'Estimated reach',
          value: tempValues.join(' | '),
        });
      }

      tempValues = [];
      Object.entries(state.estimatedImpressions).forEach(([k, v]) => {
        if (v.min || v.max) {
          let tempValue =
            v.min && v.max
              ? `${v.min}-${v.max}`
              : v.min
              ? `>${v.min}`
              : `<${v.max}`;

          tempValues.push(keyValuePostTypes[k] + ', ' + tempValue);
        }
      });
      if (tempValues.length) {
        tempFilterChicklets.push({
          key: 'est_impressions',
          title: 'Estimated impressions',
          value: tempValues.join(' | '),
        });
      }

      state.filterChicklets = tempFilterChicklets;
    },
    removeFilterChicklet: (state, action) => {
      state.filterChicklets = state.filterChicklets.filter(
        (f) => f.key != action.payload
      );
    },
    // Special reducer for hydrating the state
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.filters,
        };
      },
    },
  },
});

export const {
  setAudienceAge,
  setAudienceGender,
  setCategories,
  setKeywords,
  setInfluencerGender,
  setInfluencerLanguage,
  setInfluencerContactAvailable,
  setPerformanceFilters,
  setAudienceLocation,
  setAudienceLocationPercent,
  updateDefaultLocationFilter,
  setInfluencerLocation,
  setEstimatedFilters,
  resetPlatformFlatFilters,
  setInfluencerSize,
  resetFlatFilters,
  setFlatFiltersFromResponse,
  setSearchPhrase,
  setFilterChicklets,
  removeFilterChicklet,
} = flatFiltersSlice.actions;

export default flatFiltersSlice.reducer;
